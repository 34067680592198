ul {
  list-style-type: none;
  padding: 2vmin;
  margin: 0;
  background-color: #ffffff;
}

li {
  padding: 1vmin;
}

li a {
  padding: 2vmin;
  font-size: 5vmin;
  display: inline-block;
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  border-color: transparent;
  transition: box-shadow 0.3s ease-in, border-color 0.2s ease-in;
}

li a.active {
  outline: 0;
  box-shadow: 0 0 3vmin #00bfff;
  border-color: #00bfff;
}

li a:hover {
  box-shadow: 0 0 3vmin #00bfff;
  transition: box-shadow 0.3s ease-in;
}

.bar {
  padding-bottom: 5vmin;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
