@import url("https://fonts.googleapis.com/css?family=Quicksand");
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
@import url("https://use.fontawesome.com/releases/v5.6.3/css/all.css");

* {
  color: #00bfff;
  font-family: "Quicksand", sans-serif;
}

h1 {
  font-size: 8vmin;
  font-family: "Comfortaa", cursive;
}

h2 {
  font-size: 6vmin;
}

p {
  font-size: 3vmin;
  color: #949494;
}

span.blu {
  color: #00bfff;
}

li {
  display: inline;
}

a {
  text-decoration: underline;
  color: #949494;
}

.link-icon {
  font-size: 8vmin;
  color: #00bfff;
  padding-left: 5vmin;
  padding-right: 5vmin;
}

#intro-block {
  padding-left: 10vw;
  padding-right: 10vw;
}

#profile-pic {
  height: 20vw;
  margin-left: 5vw;
  margin-bottom: 5vw;
  border-radius: 10%;
}
